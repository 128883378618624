import React from 'react';
import style from './userProfile.module.scss';
import { Page, IndexLayout } from '../components/Layouts/Layouts';
import UserProfileContent from '../modules/SharedModules/UserProfile';

const UserProfile: React.FC = () => {
  return (
    <IndexLayout>
      <Page className={style.page}>
        <UserProfileContent />
      </Page>
    </IndexLayout>
  );
};

export default UserProfile;
